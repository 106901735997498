/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.terms-of-use__container {
  flex-grow: 1;
  height: 100%;
  background-color: #ffffff;
  padding: 64px 130px; }
  .terms-of-use__container a {
    color: #50a0d0; }
  .terms-of-use__container ol {
    margin: 1rem 0; }
    .terms-of-use__container ol li {
      margin: 1rem 0; }
  .terms-of-use__container ul {
    margin-top: 1rem;
    list-style-type: disc; }
    .terms-of-use__container ul li {
      margin: 0; }
  .terms-of-use__container em {
    font-weight: bold;
    text-decoration: underline; }
  .terms-of-use__container--address {
    text-align: center; }
    .terms-of-use__container--address p {
      margin: 0; }

@media (max-width: 768px) {
  .terms-of-use__container {
    padding: 48px 32px; } }

@media (max-width: 480px) {
  .terms-of-use__container {
    padding: 32px 16px; }
    .terms-of-use__container ol, .terms-of-use__container ul {
      padding-left: 20px; } }
