/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.popover {
  z-index: 1100;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25); }
  .popover__toggle {
    position: relative; }
  .popover__arrow {
    display: none;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 8px; }
  .popover::before, .popover::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0; }
  .popover::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent; }
  .popover::after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent; }
  .popover[data-placement$="-start"]::before {
    left: 8px; }
  .popover[data-placement$="-start"]::after {
    left: 9px; }
  .popover[data-placement^="top"] {
    margin-bottom: 12px; }
    .popover[data-placement^="top"]::before, .popover[data-placement^="top"]::after {
      bottom: -8px; }
    .popover[data-placement^="top"]::before {
      border-top: 8px solid #f4f4f4; }
    .popover[data-placement^="top"]::after {
      border-top: 7px solid #f4f4f4; }
  .popover[data-placement$="-end"]::before {
    right: 8px; }
  .popover[data-placement$="-end"]::after {
    right: 9px; }
  .popover[data-placement^="bottom"] {
    margin-top: 12px; }
    .popover[data-placement^="bottom"]::before, .popover[data-placement^="bottom"]::after {
      top: -8px; }
    .popover[data-placement^="bottom"]::before {
      border-bottom: 8px solid #f4f4f4; }
    .popover[data-placement^="bottom"]::after {
      border-bottom: 7px solid #f4f4f4; }
