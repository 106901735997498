/* stylelint-disable no-descending-specificity */
/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.tabs__list {
  list-style: none;
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  outline: 1px solid #d7d7d7; }

.tabs__tab {
  cursor: pointer;
  flex: 0 0 auto;
  background-color: unset; }
  .tabs__tab > * {
    padding: 16px; }
  .tabs__tab:hover, .tabs__tab--active {
    color: #000000; }
  .tabs__tab--active {
    background-color: #f1f2f3;
    color: #00729f; }

.tabs--horizontal .tabs__list {
  flex-direction: row; }

.tabs--horizontal .tabs__tab {
  border-bottom: 1px solid #868686;
  margin: 0 1px; }
  .tabs--horizontal .tabs__tab:first-child {
    margin-left: 0; }
  .tabs--horizontal .tabs__tab:last-child {
    margin-right: 0; }
  .tabs--horizontal .tabs__tab--active {
    border-bottom: 2px solid #00729f; }
    .tabs--horizontal .tabs__tab--active > * {
      padding-bottom: 15px; }

.tabs--vertical .tabs__list {
  flex-direction: column; }

.tabs--vertical .tabs__tab {
  border-left: 1px solid #868686;
  margin: 1px 0; }
  .tabs--vertical .tabs__tab:first-child {
    margin-top: 0; }
  .tabs--vertical .tabs__tab:last-child {
    margin-bottom: 0; }
  .tabs--vertical .tabs__tab--active {
    border-left: 2px solid #00729f; }
    .tabs--vertical .tabs__tab--active > * {
      padding-left: 15px; }

/* stylelint-enable */
