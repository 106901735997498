/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.download-app .rbt-menu {
  background-color: #ffffff;
  max-height: 220px !important; }

.download-app .dropdown-item {
  font-size: 0.75rem;
  padding: 8px 16px; }
  .download-app .dropdown-item:hover {
    background-color: #000000; }
