/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.search-bar__container {
  margin-top: 48px;
  margin-bottom: 48px; }
  .search-bar__container .search-bar .fe-search {
    padding: 0;
    font-size: 1rem; }
    .search-bar__container .search-bar .fe-search > * {
      font-size: inherit; }
    .search-bar__container .search-bar .fe-search__input {
      height: auto;
      padding: 0.5rem 2.5rem;
      border-radius: 2rem; }
    .search-bar__container .search-bar .fe-search__section--left {
      left: 1rem; }
    .search-bar__container .search-bar .fe-search__section--right {
      right: 1rem;
      opacity: 0;
      transition: opacity 500ms ease-in-out; }
    .search-bar__container .search-bar .fe-search__icon--section[role="button"]:hover {
      color: #bd4040; }
    .search-bar__container .search-bar .fe-search__input.filled ~ .fe-search__section--right {
      opacity: 1; }

@media (max-width: 1200px) {
  .search-bar__container {
    margin-top: 24px; } }
