/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.vendor-details__container {
  flex-grow: 1;
  background-color: #ffffff;
  padding: 60px 24px;
  height: 100%; }

.vendor-details__logo-img {
  width: 100%;
  border: 1px solid #d7d7d7; }

.vendor-details__name {
  vertical-align: top;
  margin: 0 0 24px; }

.vendor-details__tags {
  margin-top: 24px; }
  .vendor-details__tags h3 {
    font-weight: bold;
    margin: 0 0 16px; }

.vendor-details__info {
  margin-bottom: 48px;
  margin-top: 36px; }
  .vendor-details__info a:link,
  .vendor-details__info a:visited {
    color: #50a0d0;
    text-decoration: none; }
  .vendor-details__info h3 {
    padding-bottom: 5px;
    border-bottom: 1px solid #a6a6a6;
    margin-bottom: 24px; }
  .vendor-details__info th, .vendor-details__info td {
    text-align: left;
    vertical-align: top;
    padding: 0 0.5rem 0.5rem 0; }
  .vendor-details__info th {
    color: rgba(0, 0, 0, 0.3);
    font-weight: normal; }
    .vendor-details__info th::after {
      content: ':'; }
  .vendor-details__info td {
    padding-right: 0; }
  .vendor-details__info ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0; }

.vendor-details__overview th {
  width: 168px; }

.vendor-details__docs-and-support th {
  width: 130px; }

@media (max-width: 1200px) {
  .vendor-details__container {
    padding: 24px; }
  .vendor-details__name {
    margin-top: 24px; } }

@media (max-width: 768px) {
  .vendor-details__overview th {
    width: 130px; } }
