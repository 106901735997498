/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.collapse-icon__icon {
  transition: transform 500ms; }

.collapse-icon--open .collapse-icon__icon {
  transform: rotate(0deg); }

.collapse-icon--closed .collapse-icon__icon {
  transform: rotate(-90deg); }

.collapse-icon:not(.disabled) {
  color: #00729f; }

.collapse-icon.disabled {
  color: #b6b6b6; }
