/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.social-share__toggle {
  position: relative; }

.social-share__popover .popover__body {
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  padding-right: 3px; }
  .social-share__popover .popover__body > * {
    flex: 1 0 auto; }
  .social-share__popover .popover__body .fe-btn {
    text-align: left; }
    .social-share__popover .popover__body .fe-btn [role="img"] {
      margin-right: 3px; }
    .social-share__popover .popover__body .fe-btn [data-icon^="linkedin"] {
      vertical-align: baseline; }
