/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.faq__container {
  flex-grow: 1;
  height: 100%;
  background-color: #ffffff; }
  .faq__container.container-fluid {
    padding: 64px 130px; }

.faq a {
  color: #50a0d0; }

.faq dl {
  border-top: 1px solid #d7d7d7;
  padding: 1.5em 0; }

@media (max-width: 1200px) {
  .faq__container.container-fluid {
    padding: 48px 64px; } }

@media (max-width: 768px) {
  .faq__container.container-fluid {
    padding: 32px 24px; } }
