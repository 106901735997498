/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.tile-list {
  text-align: center;
  overflow: hidden; }
  .tile-list__title {
    text-align: left;
    margin-bottom: 24px; }
  .tile-list .tile {
    flex: 1 0 auto; }
  .tile-list__tiles {
    margin-left: -12px;
    margin-right: -12px;
    /* Add some margin because Fabric uses border-shadow only, which means
     * the borders of fe-panel are not included in width calculations */ }
    .tile-list__tiles .tile:first-child {
      margin-left: 2px; }
    .tile-list__tiles .tile:last-child {
      margin-right: 2px; }
  .tile-list__container {
    margin-bottom: 20px; }
  .tile-list__load-more {
    margin-top: 12px;
    font-size: 1rem; }
    .tile-list__load-more .loading-icon {
      display: inline-block; }

@media (min-width: 768px) {
  .no-wrap {
    flex-wrap: nowrap !important; } }

@media (max-width: 480px) {
  .no-wrap {
    flex-wrap: wrap !important; } }
