/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.hero {
  color: #ffffff;
  background-image: url(../../assets/images/HeroBackground.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: -12px;
  z-index: 1;
  /* Back to Homepage/Results link on BrowseApps and AppDetails */ }
  .hero .go-back {
    padding: 32px 24px 24px;
    color: #ffffff; }
    .hero .go-back .link {
      color: #ffffff;
      text-decoration: none;
      font-weight: bold; }
      .hero .go-back .link:hover {
        color: #ffffff; }
      .hero .go-back .link span {
        margin-left: 8px; }
  .hero__title {
    margin: 10px 24px 24px;
    font-weight: 300; }
  .go-back + .hero__title {
    margin-top: -14px; }

@media (max-width: 1200px) {
  .hero .go-back {
    padding-left: 17px; } }
