/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.error {
  background-color: #fff;
  text-align: center;
  align-self: center;
  width: 100%;
  margin: 3rem auto; }
  .error h1 {
    font-weight: 600; }
  .error a {
    color: #50a0d0;
    text-decoration: none; }
  .error [role="img"] {
    color: rgba(0, 0, 0, 0.4); }
