/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.tile .fe-panel-header .fe-label {
  font-size: 0.875rem;
  min-width: 50%;
  max-width: calc(100% - 48px);
  white-space: nowrap;
  position: relative; }
  .tile .fe-panel-header .fe-label span {
    text-align: left;
    width: 100%;
    max-width: 100%;
    padding-left: 2px;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    text-overflow: ellipsis; }

.tile .fe-panel-header [role="img"] {
  color: rgba(0, 0, 0, 0.7); }

.tile .fe-panel__body {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .tile .fe-panel__body > * {
    flex: 1 1 auto; }
  .tile .fe-panel__body a:link,
  .tile .fe-panel__body a:visited {
    text-decoration: none;
    color: #595959; }
  .tile .fe-panel__body .tile__button {
    flex: 0 1 auto;
    align-self: flex-start; }
    .tile .fe-panel__body .tile__button:link, .tile .fe-panel__body .tile__button:visited {
      color: #ffffff; }
  .tile .fe-panel__body--border {
    border: none; }

.tile__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .tile__image-container {
    position: relative;
    border: 1px solid #d7d7d7; }
    .tile__image-container:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 56.25%; }
    .tile__image-container > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.tile__summary-title {
  margin: 16px 0 0;
  font-size: 1rem;
  font-weight: 600; }

.tile__summary-text {
  font-size: 0.875rem; }

/* Styling specific to vendor tiles */
.vendor-tile .fe-panel-header .fe-label span {
  padding: 0; }
