/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.subscription-toggle {
  font-size: 0.875rem;
  margin-bottom: 0; }
  .subscription-toggle__label {
    padding-right: 8px; }
  .subscription-toggle__icon {
    color: #ffffff; }
  .subscription-toggle .react-switch-bg span {
    display: flex;
    font-size: 0.75rem;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    align-items: center; }
