/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.results-header {
  margin-bottom: 10px; }
  .results-header__row {
    margin-bottom: 18px; }
  .results-header .col {
    display: flex;
    align-items: center; }
  .results-header__title {
    margin: 0;
    display: flex;
    height: 100%;
    align-items: center; }
    .results-header__title .fe-badge, .results-header__title .fe-spinner {
      margin: 3px 0 0 8px; }
  .results-header__sort {
    text-align: right; }
  .results-header__filters {
    margin-top: 16px;
    margin-bottom: 0;
    padding-left: 11px;
    padding-right: 11px; }
    .results-header__filters .fe-pill-box:first-of-type {
      margin-left: 4px; }
    .results-header__filters .col-auto {
      padding: 0 4px 8px; }

@media (max-width: 480px) {
  .results-header__row {
    flex-direction: column-reverse; }
  .results-header__title {
    margin-top: 16px; }
  .results-header__sort .fe-btn {
    width: 100%; } }
