/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.subscription-settings .subscription-toggle {
  margin-top: 0.85rem; }
  .subscription-settings .subscription-toggle__label {
    display: none; }

.subscription-settings__header {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 1.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  justify-items: start;
  align-items: center; }
  .subscription-settings__header > * {
    flex: 0 1 auto;
    margin-left: 0.5rem;
    margin-bottom: 0;
    margin-top: 0; }
  .subscription-settings__header .collapse-icon {
    margin: 0;
    flex-shrink: 0; }
  .subscription-settings__header .title, .subscription-settings__header .collapse-icon, .subscription-settings__header .loading-icon {
    font-size: 1.25rem; }
  .subscription-settings__header .counter {
    flex-shrink: 0; }
  .subscription-settings__header .subhead {
    font-size: 1rem;
    flex: 1 1 100%;
    margin: 1rem 0; }
  .subscription-settings__header .loading-icon {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end; }
