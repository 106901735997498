/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.app-details__container {
  flex-grow: 1;
  background-color: #ffffff;
  padding: 60px 24px;
  height: 100%; }

.app-details__description {
  margin-bottom: 24px; }
  .app-details__description--app-name {
    vertical-align: top;
    margin: 0 0 24px; }
  .app-details__description--text a:link,
  .app-details__description--text a:visited {
    color: #50a0d0;
    text-decoration: none; }
  .app-details__description--download {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
    .app-details__description--download .form-check {
      padding: 0;
      margin-bottom: 24px;
      font-size: 0.875rem; }
      .app-details__description--download .form-check:hover {
        background-color: transparent; }
    .app-details__description--download > * {
      flex: 0 1 auto; }
    .app-details__description--download p {
      flex-basis: 100%; }
    .app-details__description--download .assent,
    .app-details__description--download .social-share,
    .app-details__description--download .no-download__button,
    .app-details__description--download .external-link.fe-btn {
      align-self: flex-start;
      margin-right: 32px; }
      .app-details__description--download .assent:last-child,
      .app-details__description--download .social-share:last-child,
      .app-details__description--download .no-download__button:last-child,
      .app-details__description--download .external-link.fe-btn:last-child {
        margin-right: 0; }
    .app-details__description--download .subscription-toggle {
      margin-left: auto;
      margin-right: 16px; }
  .app-details__description .read-more {
    margin-bottom: 12px; }
    .app-details__description .read-more * {
      font-size: 0.875rem; }
  .app-details__description--tags {
    margin-top: 24px; }
    .app-details__description--tags h3 {
      font-weight: bold;
      margin: 0 0 16px; }

.app-details__info {
  margin: 36px 0 48px !important; }
  .app-details__info a:link,
  .app-details__info a:visited {
    color: #50a0d0;
    text-decoration: none; }
  .app-details__info h3 {
    padding-bottom: 5px;
    border-bottom: 1px solid #a6a6a6;
    margin-bottom: 24px; }
  .app-details__info em {
    color: rgba(0, 0, 0, 0.3); }
  .app-details__info .app-details__distributions td {
    text-overflow: unset;
    white-space: normal; }
  .app-details__info .app-details__distributions .labels-column {
    width: 140px; }
  .app-details__info .app-details__support .labels-column {
    width: 110px; }
  .app-details__info .app-details__support .external-link {
    display: block;
    margin-bottom: 4px;
    white-space: normal; }

@media (max-width: 1200px) {
  .app-details__description--app-name {
    margin-top: 24px; }
  .app-details-table {
    margin-bottom: 24px; } }

@media (max-width: 768px) {
  .social-share-popover .fe-btn {
    padding: 0.75rem 1rem; }
  .app-details__container {
    padding: 24px 12px; }
  .app-details__description--download {
    justify-content: space-between; }
  .app-details__info {
    margin: 12px -16px !important; }
  .app-details .app-details-table {
    width: 100% !important;
    max-width: 100% !important; } }

@media (max-width: 480px) {
  .app-details__description {
    margin-bottom: 0; }
    .app-details__description--download .fe-btn {
      text-align: center;
      font-size: 1rem;
      padding-top: 16px;
      padding-bottom: 16px;
      width: 100%;
      margin: 0 auto 16px;
      height: 64px; }
    .app-details__description--download .assent,
    .app-details__description--download .social-share {
      flex: 1 0 100%; }
    .app-details__description--download .subscription-toggle {
      margin: auto; }
  .social-share__popover {
    width: 92.5%; }
    .social-share__popover .popover__body {
      padding-top: 6px;
      padding-bottom: 6px; }
      .social-share__popover .popover__body .fe-btn {
        margin-top: 4px;
        margin-bottom: 4px; } }
