/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.filter-group {
  border-bottom: 1px solid #a6a6a6;
  position: relative;
  /* Title/Toggle */ }
  .filter-group__title {
    font-weight: 600;
    padding: 16px 0 8px;
    text-align: left;
    border: none;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .filter-group__title:hover {
      background-color: #e1e1e1; }
    .filter-group__title-text {
      font-size: 1rem;
      font-weight: 600; }
  .filter-group__collapse:not(.over-height) {
    padding-bottom: 8px; }
  .filter-group__collapse input:invalid {
    box-shadow: none !important; }
  .filter-group__collapse--list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-y: auto;
    max-height: 292px;
    /* Add some padding and margin to the last item in the list ONLY if the scrollbar is present */
    /* Checkboxes */ }
    .filter-group__collapse--list li.pad-bottom {
      margin-right: 4px;
      padding-bottom: 24px; }
    .filter-group__collapse--list .form-check,
    .filter-group__collapse--list .form-radio {
      padding: 6px 0; }
      .filter-group__collapse--list .form-check label,
      .filter-group__collapse--list .form-radio label {
        font-size: 0.875rem; }
        .filter-group__collapse--list .form-check label em,
        .filter-group__collapse--list .form-radio label em {
          font-style: normal;
          font-weight: 700; }
  .filter-group__collapse--fade-out {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    height: 45px;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.5) 25%, whitesmoke 100%); }
  .filter-group .fe-collapse--closed + .filter-group__collapse--fade-out {
    display: none; }
