/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.notification-center {
  background-color: #ffffff; }
  .notification-center__container--content {
    padding-top: 32px;
    padding-bottom: 64px; }
    .notification-center__container--content--title {
      padding: 16px 0;
      border-bottom: 1px solid #d7d7d7;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .notification-center__container--content--title h2 {
        margin: 0; }
  .notification-center__load-more {
    margin-top: 12px;
    display: block;
    font-size: 1rem; }
