/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
/* Variables - Colors */
/* We probably will never need this variable but i'm including it for the sake of completeness */
/* fe-border-main */
/* Variables - Colors - Dispositions */
/* Variables - Colors - Icon */
/* fe-info-dim - not used in Market */
/* Variables - Font Sizes */
/* Market does not have a variable for an 8x font size */
/* Variables - Padding and Indents */
.notification {
  background-color: #d7d7d7;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  padding: 8px 12px;
  /* Coloring for specific dispositions */
  /* Specific to rolled up notifications */
  /* Coloring for read/unread notifications */ }
  .notification:first-of-type {
    border-top: 1px solid #f4f4f4; }
  .notification__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    padding-bottom: 2px; }
    .notification__header-source {
      font-size: 0.5rem;
      color: rgba(0, 0, 0, 0.3);
      align-self: flex-start; }
    .notification__header-date {
      font-size: 0.5rem;
      color: rgba(0, 0, 0, 0.3);
      align-self: flex-end; }
  .notification__body {
    display: flex;
    justify-content: flex-start;
    cursor: pointer; }
    .notification__body-icon {
      font-size: 1rem !important;
      color: #99ddff;
      margin-top: 2px;
      margin-right: 8px; }
    .notification__body-message {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.9);
      margin: 0; }
      .notification__body-message-group-icon {
        margin-right: 4px; }
  .notification--success:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: #5fc7a2; }
  .notification--success .notification__body-icon {
    color: #5fc7a2; }
  .notification--success.notification--is-child .notification__body-icon {
    color: #5fc7a2 !important; }
  .notification--warning:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: #f8b024; }
  .notification--warning .notification__body-icon {
    color: #f8b024; }
  .notification--warning.notification--is-child .notification__body-icon {
    color: #f8b024 !important; }
  .notification--error:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: #bd4040; }
  .notification--error .notification__body-icon {
    color: #bd4040; }
  .notification--error.notification--is-child .notification__body-icon {
    color: #bd4040 !important; }
  .notification--info:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: #99ddff; }
  .notification--info .notification__body-icon {
    color: #99ddff; }
  .notification--info.notification--is-child .notification__body-icon {
    color: #99ddff !important; }
  .notification.notification--has-group :last-of-type {
    border-bottom: none; }
  .notification__collapse {
    background-color: #d7d7d7;
    margin-top: 8px;
    margin-right: -12px;
    margin-left: -12px;
    position: relative;
    margin-bottom: -8px; }
    .notification__collapse:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      width: 6px;
      background-color: #ffffff; }
    .notification__collapse .notification {
      margin-left: 6px; }
  .notification--unread {
    background-color: #E6F3FA; }
    .notification--unread .notification__body-message {
      font-weight: bold;
      overflow: hidden;
      position: relative;
      line-height: 1.25em;
      max-height: 3.75em;
      text-align: justify;
      padding-right: 1em; }
      .notification--unread .notification__body-message:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0; }
      .notification--unread .notification__body-message:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.3em;
        background-color: #E6F3FA; }
  .notification--red {
    background-color: #d7d7d7; }
    .notification--red .notification__body-message {
      font-weight: normal;
      overflow: hidden;
      position: relative;
      line-height: 1.25em;
      max-height: 3.75em;
      text-align: justify;
      padding-right: 1em; }
      .notification--red .notification__body-message:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0; }
      .notification--red .notification__body-message:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.3em;
        background-color: #E6F3FA; }
