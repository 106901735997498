/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.loading .caption {
  color: #00729f; }

.loading .fe-spinner--sm + .caption {
  font-size: 0.625rem; }

.loading .fe-spinner--l + .caption {
  font-size: 0.75rem; }

.loading .fe-spinner--xl + .caption {
  font-size: 1rem; }

.loading .fe-spinner--xxl + .caption {
  font-size: calc(3 * 1.125rem); }

.fe-stacked-spinner.loading {
  display: flex; }
  .fe-stacked-spinner.loading .caption {
    padding-bottom: 2px; }
  .fe-stacked-spinner.loading .fe-spinner--l + .caption {
    font-size: 0.75rem; }
  .fe-stacked-spinner.loading .fe-spinner--xl + .caption {
    font-size: 0.875rem; }
  .fe-stacked-spinner.loading .fe-spinner--xxl + .caption {
    font-size: 0.875rem; }
