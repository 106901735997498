/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.fe-btn, [role="button"], button {
  transition: all 250ms ease-in-out;
  cursor: pointer; }

/* Global button customizations */
.fe-btn {
  position: unset;
  /* Will need to be reverted when https://ghe.eng.fireeye.com/fabric-community/fe-fabricv2/issues/117 is fixed */ }
  .fe-btn--sm {
    line-height: 20px; }
  .fe-btn--md {
    line-height: 30px; }
  .fe-btn--lg {
    line-height: 46px; }
  .fe-btn .fe-icon--let {
    margin-left: -2px; }
  .fe-btn--secondary.disabled, .fe-btn--secondary[disabled] {
    cursor: not-allowed;
    background-color: #f1f2f3;
    color: #b6b6b6;
    border-color: #0000; }

.fe-btn--link {
  border: 0; }
  .fe-btn--link.inline {
    padding: 0;
    line-height: inherit;
    font-size: inherit;
    background-color: transparent;
    height: auto; }
    .fe-btn--link.inline:hover, .fe-btn--link.inline:active, .fe-btn--link.inline:focus {
      background-color: transparent; }

.fe-top-nav {
  height: 54px;
  max-height: 54px; }
  .fe-top-nav__bookend {
    padding: 13px 24px; }
    .fe-top-nav__bookend:before {
      height: 54px; }
  .fe-top-nav__bookend--left {
    padding-right: 6px; }
    .fe-top-nav__bookend--left::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: calc(100% + 2px);
      height: 3px;
      background: linear-gradient(272.19deg, #0FB8DC 0.24%, #26D96E 99.76%); }
  .fe-top-nav__bookend--right {
    padding-left: 6px; }
  .fe-top-nav__bar-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    height: 100%; }
    .fe-top-nav__bar-nav-items {
      display: block;
      flex: 0 1 auto;
      height: 100%; }
    .fe-top-nav__bar-nav .fe-btn {
      display: block;
      padding-right: 1rem;
      padding-left: 1rem;
      height: calc(100% + 1px); }
      .fe-top-nav__bar-nav .fe-btn.active, .fe-top-nav__bar-nav .fe-btn--link.active {
        background: transparent;
        color: #ffffff;
        border-bottom-width: 2px;
        border-bottom-color: #ffffff;
        border-bottom-style: solid; }

@media (max-width: 1200px) {
  .header {
    height: 48px; }
    .header::after {
      content: '';
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 3px;
      background: linear-gradient(272.19deg, #0FB8DC 0.24%, #26D96E 99.76%); }
  .fe-top-nav__topnav {
    background: linear-gradient(0deg, #e1e1e1 0%, #f5f5f5 100%); }
    .fe-top-nav__topnav .fe-top-nav__bar {
      box-shadow: none;
      padding-left: 0;
      z-index: 101;
      background: transparent; }
      .fe-top-nav__topnav .fe-top-nav__bar-nav-items:first-child {
        margin-left: 8px; }
      .fe-top-nav__topnav .fe-top-nav__bar-nav .fe-btn {
        font-size: 0.75rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: calc(100% - 3px); } }

.fe-input:disabled {
  border: 1px solid transparent; }

.fe-panel {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column; }
  .fe-panel-header {
    box-shadow: none;
    background-color: #dcdcdc; }
  .fe-panel__body {
    box-shadow: none;
    flex: 1 1 auto; }

.fe-icon {
  margin: 0px 0px 0px 0px;
  padding: 0px;
  font-size: inherit;
  height: auto;
  width: auto; }
  .fe-icon--left {
    margin: 0px 4px 0px 0px; }
  .fe-icon--right {
    margin: 0px 0px 0px 4px; }

/* Modal Customizations */
.fe-modal {
  background-color: rgba(0, 0, 0, 0.7); }
  .fe-modal__dialog {
    /* App Carousel */
    /* Contact and Download */ }
    .fe-modal__dialog--fullscreen .fe-modal__content {
      height: 98vh;
      width: 98vw;
      display: flex;
      flex-direction: column;
      overflow: scroll; }
      .fe-modal__dialog--fullscreen .fe-modal__content .fe-modal__body {
        overflow: auto;
        flex: 1 0 auto; }
    .fe-modal__dialog .fe-modal__content {
      height: 100%; }
      .fe-modal__dialog .fe-modal__content form {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        min-height: 100%;
        height: 100%; }
        .fe-modal__dialog .fe-modal__content form .fe-modal__header {
          flex: 0 0 auto; }
        .fe-modal__dialog .fe-modal__content form .fe-modal__body {
          margin: 0;
          padding: 16px 16px 0;
          flex: 1 1 auto;
          position: relative;
          overflow-x: hidden;
          overflow-y: scroll; }
        .fe-modal__dialog .fe-modal__content form .fe-modal__footer {
          flex: 0 0 auto; }

.fe-form__input-wrapper--has-icon textarea + .fe-input-icon {
  top: 1.5em; }

.fe-form__input-wrapper .fe-input-hint-text {
  white-space: pre-line;
  display: inline-block;
  transition: margin 250ms ease-in-out; }
  .fe-form__input-wrapper .fe-input-hint-text:empty {
    margin: 0; }

.fe-pill-box {
  padding: 0 12px; }
  .fe-pill-box.with-icon {
    padding-left: 12px; }
