/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.app-details-table {
  table-layout: fixed;
  margin-bottom: 0;
  width: 100%;
  max-width: 100%; }
  .app-details-table td {
    padding: 2px 0;
    vertical-align: text-top;
    white-space: normal;
    position: relative; }
  .app-details-table .labels-column {
    color: rgba(0, 0, 0, 0.3);
    padding-right: 8px;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .app-details-table .hash {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    overflow: hidden;
    min-width: 100%;
    width: 100%;
    word-wrap: break-word;
    font-size: 98%;
    text-transform: uppercase; }

@media (min-width: 1200px) {
  .app-details-table .hash {
    font-size: 90%; } }
