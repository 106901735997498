/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
/* Font smoothing */
html {
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  background-color: #d7d7d7; }

/* Remove focus outline from everything */
*:focus {
  outline: none; }

/* Header weight normalization */
h1,
h6 {
  font-weight: 400; }

h2,
h3,
h4,
h5 {
  font-weight: 600; }

/* Global Helper classes */
.pointer {
  cursor: pointer; }

/* Global Body and App Container styles */
#root {
  height: 100vh;
  display: flex;
  flex-direction: column; }
  #root .app-container {
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column; }
    #root .app-container .header {
      flex-grow: 0; }
    #root .app-container .app-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column; }
      #root .app-container .app-content .hero {
        flex-grow: 0; }
      #root .app-container .app-content__container {
        flex-grow: 1;
        background-color: #ffffff;
        padding: 0 !important;
        display: flex;
        position: relative; }
        #root .app-container .app-content__container > .row {
          flex-grow: 1; }
        #root .app-container .app-content__container .filters-col {
          background-color: #f5f5f5; }
    #root .app-container .footer {
      flex-grow: 0;
      align-self: flex-end; }

/* Container for apps on Home and BrowseApps */
.right-col {
  padding: 0 20px !important; }

/* Flex-Row and Utility Classes */
.flex-row {
  display: flex;
  flex-wrap: wrap; }
  .flex-row > [class*='col-'] {
    display: flex;
    flex-direction: column; }
  .flex-row .row:after,
  .flex-row .row:before {
    display: flex; }

/* Global styles */
hr {
  border-color: #f4f4f4; }

.popover {
  border-radius: 4px; }

@media (max-width: 1200px) {
  .right-col {
    padding: 16px !important; } }

@media (max-width: 768px) {
  .right-col {
    padding: 8px !important; } }
