/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.awesome-check label, .awesome-check .awesome-check__icon {
  vertical-align: middle;
  line-height: 1; }
  .awesome-check label:hover, .awesome-check .awesome-check__icon:hover {
    cursor: pointer; }

.awesome-check input[type='checkbox'] {
  display: none; }

.awesome-check .awesome-check__input-icon {
  color: rgba(0, 0, 0, 0.7); }

.awesome-check:hover {
  background-color: #e1e1e1; }

.awesome-check--checked .awesome-check__input-icon {
  color: #50a0d0; }
