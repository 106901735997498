/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.app-subscription {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 1.5rem;
  display: flex;
  align-content: stretch;
  justify-content: space-between; }
  .app-subscription__header {
    flex: 1 100%; }
  .app-subscription__body {
    flex: 1 auto;
    margin-left: 0.75rem; }
