/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.notifications-menu {
  z-index: 1; }
  .notifications-menu__toggle {
    color: rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around; }
    .notifications-menu__toggle .fe-badge {
      padding-left: 0.5em;
      padding-right: 0.5em;
      margin: -4px 0 0 -4px;
      font-size: 0.625rem; }
  .notifications-menu__popover {
    background-color: #F9F9F9;
    width: 405px;
    display: flex;
    flex-direction: column;
    max-height: 90vh; }
    .notifications-menu__popover .popover__body {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding-top: 12px;
      position: relative;
      overflow-y: auto;
      /* Set a max-height for IE11 only (Edge does not support -ms-high-contrast) */ }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .notifications-menu__popover .popover__body {
          max-height: 500px; } }
      .notifications-menu__popover .popover__body .popover-header {
        background-color: #F9F9F9;
        padding: 0 12px 12px;
        width: 100%;
        flex: 0 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center; }
        .notifications-menu__popover .popover__body .popover-header .fe-btn {
          padding: 0;
          height: unset; }
        .notifications-menu__popover .popover__body .popover-header__title {
          color: rgba(0, 0, 0, 0.7);
          font-size: 0.75rem;
          font-weight: bold;
          line-height: 30px;
          flex-grow: 1; }
        .notifications-menu__popover .popover__body .popover-header .mark-all-read {
          flex-grow: 0;
          padding: 0 12px; }
        .notifications-menu__popover .popover__body .popover-header__settings {
          color: #00729f;
          font-size: 1.125rem;
          margin-left: 4px;
          flex-grow: 0; }
          .notifications-menu__popover .popover__body .popover-header__settings.active, .notifications-menu__popover .popover__body .popover-header__settings *:visited {
            background-color: transparent;
            color: #00729f; }
          .notifications-menu__popover .popover__body .popover-header__settings:hover {
            background-color: transparent;
            color: #50a0d0; }
        .notifications-menu__popover .popover__body .popover-header__closer {
          color: #00729f;
          font-size: 1.125rem;
          margin-left: 12px;
          display: none; }
    .notifications-menu__popover .notifications-menu__view-all {
      width: 100%;
      color: #00729f;
      font-weight: bold;
      text-align: center;
      padding: 20px;
      line-height: 0;
      flex: 0 0 auto;
      align-self: flex-end;
      z-index: 1070; }
      .notifications-menu__popover .notifications-menu__view-all, .notifications-menu__popover .notifications-menu__view-all:active {
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08) !important; }

@media (max-width: 768px) {
  .notifications-no-scroll {
    overflow: hidden; }
  .notifications-menu__popover {
    transform: none !important;
    border: none;
    border-radius: 0 !important;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw !important;
    height: 100vh;
    min-height: 100vh; }
    .notifications-menu__popover .arrow {
      display: none; }
    .notifications-menu__popover .popover__body {
      height: 100vh;
      padding: 0; }
      .notifications-menu__popover .popover__body .popover-header {
        padding-top: 16px; }
      .notifications-menu__popover .popover__body .popover-header__closer {
        display: block; } }
