/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.home .hero .fe-hero {
  background-color: transparent;
  margin-bottom: 0 !important;
  text-align: center;
  padding: 68px 24px; }
  .home .hero .fe-hero__title {
    margin-top: 0; }
    .home .hero .fe-hero__title-text {
      font-size: 3rem;
      font-weight: 700;
      margin-left: auto;
      margin-right: auto; }
  .home .hero .fe-hero__body {
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    max-width: 750px; }
  .home .hero .fe-hero .browse-button {
    background-color: #f5f5f5;
    color: #50a0d0;
    font-size: 1rem;
    width: 200px;
    height: 40px;
    line-height: 40px;
    border: none; }
    .home .hero .fe-hero .browse-button:hover {
      color: #74b4da; }
    .home .hero .fe-hero .browse-button--vendors {
      margin-left: 16px; }

.home .apps__container .promo {
  background: linear-gradient(0deg, #e1e1e1 0%, #f5f5f5 100%);
  text-align: center;
  padding: 24px;
  margin-top: 28px;
  margin-bottom: 48px; }
  .home .apps__container .promo .fe-btn {
    width: 155px; }
    .home .apps__container .promo .fe-btn--secondary {
      background-color: #f5f5f5; }
  .home .apps__container .promo__get-involved .fe-btn:first-of-type {
    margin-right: 24px; }

@media (max-width: 1200px) {
  .home .hero .page-title {
    font-size: 2rem !important; }
  .home .hero .fe-hero__body {
    font-size: 0.875rem !important; } }

@media (max-width: 480px) {
  .home .hero .page-title {
    font-size: 2rem !important; }
  .home .hero .browse-button--apps {
    margin-bottom: 16px; }
  .home .hero .browse-button--vendors {
    margin-left: 0 !important; }
  .home .promo {
    padding: 24px 16px !important; }
    .home .promo h2 {
      margin: 0 auto 12px; }
    .home .promo .fe-btn {
      width: auto !important;
      padding-left: 32px;
      padding-right: 32px; }
    .home .promo__browse h2 {
      font-size: 1.125rem; }
    .home .promo__get-involved .promo__text {
      font-size: 0.875rem; }
    .home .promo__get-involved .fe-btn:first-of-type {
      margin-right: 8px !important; } }
