/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.footer {
  background-color: #444444;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.75rem;
  padding: 19px 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .footer__copyright {
    align-self: flex-start; }
  .footer__links {
    align-self: flex-end;
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0; }
    .footer__links-link {
      margin: 0 8px;
      display: inline-block; }
      .footer__links-link:last-child {
        margin-right: 0; }
      .footer__links-link a,
      .footer__links-link .fe-btn--link {
        color: rgba(255, 255, 255, 0.8);
        background-color: transparent;
        text-decoration: none;
        font-size: 0.75rem;
        font-weight: 400 !important;
        line-height: 12px;
        letter-spacing: 1.2px;
        padding: 0;
        vertical-align: middle;
        height: auto !important;
        text-transform: uppercase; }
        .footer__links-link a:hover,
        .footer__links-link .fe-btn--link:hover {
          background-color: transparent;
          color: inherit;
          text-decoration: underline; }

@media (max-width: 1200px) {
  .footer {
    flex-direction: column; }
    .footer__copyright {
      align-self: center;
      display: block;
      margin-bottom: 8px; }
    .footer__links {
      align-self: center;
      text-align: center; }
      .footer__links-link {
        display: block;
        padding: 2px 0; } }

@media (max-width: 768px) {
  .footer__links {
    flex-direction: column; } }
