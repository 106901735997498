/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.vendor-compatible-apps .tile-list__title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between; }
  .vendor-compatible-apps .tile-list__title > * {
    flex: 0 0 auto; }
  .vendor-compatible-apps .tile-list__title .fe-badge {
    vertical-align: middle;
    margin-left: 0.5rem; }

.vendor-compatible-apps__header {
  margin-bottom: 0; }

@media (max-width: 768px) {
  .tile-list__title {
    flex-direction: column;
    flex-wrap: wrap; }
    .tile-list__title .vendor-compatible-apps__by-developer {
      padding: 0;
      margin: 4px 0 0 4px; } }
