/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.notifications__section {
  overflow: hidden; }
  .notifications__section-title {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.625rem;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 12px 8px 4px; }
  .notifications__section--earlier {
    background-color: #ffffff; }
  .notifications__section .notification.animate-enter {
    opacity: 0.01;
    transform: translateY(-200%); }
  .notifications__section .notification.animate-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 250ms ease-out; }
  .notifications__section .notification.animate-exit {
    opacity: 1;
    transform: translateY(0); }
  .notifications__section .notification.animate-exit-active {
    opacity: 0.01;
    transform: translateY(-200%);
    transition: all 250ms ease-out; }
  .notifications__section .notification__body-message a {
    color: inherit;
    text-decoration: none; }
    .notifications__section .notification__body-message a:hover, .notifications__section .notification__body-message a:focus {
      text-decoration: underline; }

.notifications--empty {
  text-align: center;
  padding: 24px 12px; }
