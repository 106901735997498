/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.logo {
  z-index: 1; }
  .logo [role="img"] {
    width: 109px;
    height: 28px; }

@media (max-width: 1200px) {
  .logo [role="img"] {
    height: 20px; } }
