/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.app-carousel {
  /* Top Level Container for Slides */
  /* Container for thumbnails */ }
  .app-carousel .carousel .slide {
    background: #ffffff; }
  .app-carousel .carousel-slider {
    border: 1px solid #d7d7d7; }
    .app-carousel .carousel-slider .slider-wrapper .slide .image-container {
      position: relative; }
      .app-carousel .carousel-slider .slider-wrapper .slide .image-container:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%; }
      .app-carousel .carousel-slider .slider-wrapper .slide .image-container > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .app-carousel .carousel-slider .slider-wrapper .slide .image-container__image {
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%); }
    .app-carousel .carousel-slider .slider-wrapper .slide .video-thumbnail {
      opacity: 0; }
    .app-carousel .carousel-slider .slider-wrapper .slide .react-player {
      position: absolute;
      top: 0;
      left: 0; }
  .app-carousel .thumbs-wrapper {
    margin: 16px 12px 0 !important; }
    .app-carousel .thumbs-wrapper .control-arrow {
      padding: 0;
      margin-top: -10px; }
    .app-carousel .thumbs-wrapper .control-prev.control-arrow:before {
      border-right: 8px solid #595959;
      margin-left: 1px; }
    .app-carousel .thumbs-wrapper .control-next.control-arrow:before {
      border-left: 8px solid #595959;
      margin-right: 1px; }
    .app-carousel .thumbs-wrapper .thumbs {
      margin: 0;
      padding: 0 4px !important; }
      .app-carousel .thumbs-wrapper .thumbs .thumb {
        position: relative;
        border: 1px solid #d7d7d7;
        margin-right: 4px; }
        .app-carousel .thumbs-wrapper .thumbs .thumb:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 56.25%; }
        .app-carousel .thumbs-wrapper .thumbs .thumb > div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
        .app-carousel .thumbs-wrapper .thumbs .thumb img {
          position: absolute;
          top: 50%;
          left: 50%;
          min-height: 100%;
          min-width: 100%;
          transform: translate(-50%, -50%); }
        .app-carousel .thumbs-wrapper .thumbs .thumb.selected {
          border: 2px solid #50a0d0; }

/* Container for app image lightbox */
.app-carousel-lightbox.fe-modal {
  min-width: 100%;
  width: 100%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.8); }
  .app-carousel-lightbox.fe-modal__dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    margin: 0; }
    .app-carousel-lightbox.fe-modal__dialog .fe-modal__content {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      margin: 0;
      background-color: transparent;
      box-shadow: none;
      padding: 5px 15px 37px 15px; }
      .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__header {
        width: 100%;
        background-color: transparent;
        flex-direction: row-reverse;
        padding: 0; }
        .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__header .fe-modal-close {
          cursor: pointer;
          color: #ffffff; }
      .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        overflow: hidden;
        align-items: center; }
        .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel {
          width: 100%;
          max-width: 1200px;
          height: auto;
          margin: 0 auto; }
          .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .carousel-slider {
            display: inline-block;
            overflow: visible; }
            .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .carousel-slider .slider-wrapper {
              width: calc(100% - ((1.5rem * 2) + 20px)); }
            .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .carousel-slider .slide {
              background-color: transparent; }
          .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .control-arrow {
            opacity: 0.9;
            padding: 0; }
            .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .control-arrow:before {
              border-top-width: 1.5rem;
              border-bottom-width: 1.5rem; }
          .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .control-prev:before {
            border-right-width: 1.5rem; }
          .app-carousel-lightbox.fe-modal__dialog .fe-modal__content .fe-modal__body .lightbox-carousel .control-next:before {
            border-left-width: 1.5rem; }

@media (max-width: 1200px) {
  .app-carousel-lightbox .fe-modal__body {
    margin: 0; }
  .app-carousel-lightbox .slider-wrapper {
    width: 100% !important; }
  .app-carousel-lightbox .control-arrow {
    display: none; } }
