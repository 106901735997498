/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.assent-form-group {
  width: 100%; }
  .assent-form-group .preamble {
    font-weight: bold; }
  .assent-form-group .form-check-label {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .assent-form-group .assent-to-terms__link {
    border: 0;
    padding: 0 !important;
    font-size: inherit;
    color: #50a0d0;
    text-decoration: none; }
    .assent-form-group .assent-to-terms__link:visited {
      color: #50a0d0;
      text-decoration: none; }

.assent {
  display: inline-block; }
