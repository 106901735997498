/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.avatar-menu .fe-avatar__circle--no-image {
  background: linear-gradient(360deg, #416C82 15.59%, #418DB1 84.41%);
  color: #ffffff;
  text-indent: 1px; }

.avatar-menu__popover {
  text-align: center;
  background-color: #ffffff; }
  .avatar-menu__popover .popover__body {
    padding: 16px;
    width: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .avatar-menu__popover .popover__body > * {
      flex: 1 0 100%; }
  .avatar-menu__popover .fe-avatar__label {
    margin-bottom: 24px; }
    .avatar-menu__popover .fe-avatar__label-name {
      text-transform: capitalize; }
  .avatar-menu__popover--sign-out-icon {
    color: #868686;
    margin-right: 8px; }
  .avatar-menu__popover--sign-out:hover, .avatar-menu__popover--sign-out:hover *, .avatar-menu__popover--sign-out:active, .avatar-menu__popover--sign-out:active * {
    color: #00729f !important;
    background-color: #d7d7d7 !important; }
