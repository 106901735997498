.not-found__icon {
  font-size: 64px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 24px; }

.not-found .app-content__container {
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .not-found .app-content__container h1 {
    font-weight: 600;
    margin-bottom: 8px; }
