/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.no-download {
  color: rgba(0, 0, 0, 0.9); }
  .no-download a:link,
  .no-download a:visited {
    color: #50a0d0;
    text-decoration: none; }
  .no-download [role="img"] {
    margin-right: 4px; }
    .no-download [role="img"].unauthorized {
      color: #f4716b; }
    .no-download [role="img"].unauthenticated {
      color: #c08100; }
    .no-download [role="img"].key-required {
      color: #afafaf; }
  .no-download .fe-btn--link {
    color: #50a0d0;
    font-weight: normal;
    width: auto !important;
    margin: 0 !important; }
  .no-download__button {
    margin: 0.75rem 1.5rem 0.75rem 0; }
