/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.filters {
  padding: 24px;
  background-color: #f5f5f5;
  flex-flow: column nowrap !important;
  height: 100%; }
  .filters .loading {
    margin-top: 16px; }
  .filters__title {
    background-color: #f5f5f5;
    font-size: 1.125rem;
    font-weight: 600;
    padding-bottom: 2px;
    border-bottom: 1px solid #a6a6a6;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between; }
    .filters__title-wrapper {
      background-color: #f5f5f5; }
    .filters__title-content {
      line-height: 1;
      display: flex;
      align-items: center; }
      .filters__title-content--icon {
        margin-right: 8px; }
      .filters__title-content .fe-badge, .filters__title-content .loading-icon {
        margin-left: 8px; }
    .filters__title-clear-button {
      text-transform: uppercase;
      padding: 4px 8px;
      display: block;
      align-self: flex-end; }
      .filters__title-clear-button.fe-btn--md {
        line-height: 1;
        height: unset; }
        .filters__title-clear-button.fe-btn--md:hover {
          background-color: transparent; }
      .filters__title-clear-button.disabled, .filters__title-clear-button:disabled {
        display: none; }

@media (min-width: 1200px) {
  .filters {
    box-shadow: 3px 0.5px 3px -2px rgba(0, 0, 0, 0.25); }
    .filters__title-content, .filters__title-clear-button {
      padding-top: 10px;
      padding-bottom: 10px; }
    .filters__title-clear-button {
      align-self: center; }
    .filters__title-wrapper--static {
      display: block; }
    .filters__title-wrapper--collapsible {
      display: none; }
    .filters--open .filters__collapse, .filters--closed .filters__collapse {
      opacity: 1 !important; } }

@media (max-width: 1200px) {
  .filters {
    padding: 0;
    /* Only add drop shadow when filters are sticky  */ }
    .filters__title {
      padding: 14px 16px;
      font-size: 1rem;
      border: 0; }
      .filters__title-wrapper--static {
        display: none; }
      .filters__title-wrapper--collapsible {
        display: block;
        position: relative;
        z-index: 101; }
      .filters__title-content--icon {
        color: #50a0d0; }
    .filters--open {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      overflow-y: scroll;
      z-index: 101; }
      .filters--open .sticky-outer-wrapper {
        height: 51px;
        background-color: #f5f5f5; }
        .filters--open .sticky-outer-wrapper .sticky-inner-wrapper {
          position: fixed !important;
          top: 0 !important;
          width: 100vw !important;
          background-color: #f5f5f5; }
      .filters--open .filters__title {
        box-shadow: none !important;
        border-bottom: 1px solid #a6a6a6;
        background-color: #f5f5f5; }
    .filters--closed .filters__collapse--open {
      display: block; }
    .filters--closed .filters__collapse--closed {
      display: none; }
    .filters .sticky-outer-wrapper.active .filters__title {
      box-shadow: inset -1px 0 1px 0 #a6a6a6, 0 2px 8px 0 rgba(0, 0, 0, 0.25); } }
