/* Mixin to maintain image aspect ratio */
/* mixin for multiline ellipsis */
.header {
  z-index: 100; }
  .header .fe-top-nav__home {
    transition: all 0.25s ease-in; }
  .header .fe-top-nav__action-container {
    display: flex;
    align-content: center; }
  .header .fe-top-nav__bar-nav .fe-btn {
    color: #00729f;
    line-height: 50px;
    height: 100%; }
    .header .fe-top-nav__bar-nav .fe-btn.active {
      color: #000000;
      border-bottom: 2px solid #000000; }
  .header .notifications-menu__popover--icon {
    font-size: 1rem; }
  .header .login {
    font-size: 0.875rem;
    font-weight: 800;
    z-index: 1;
    background-color: transparent; }
    .header .login:hover {
      background-color: transparent; }
  .header .avatar-menu {
    margin-right: 0; }

@media (max-width: 1200px) {
  .header .login {
    font-size: 0.75rem; }
  .header .fe-top-nav__bar {
    background-image: linear-gradient(0deg, #e1e1e1 0%, #f5f5f5 100%); }
    .header .fe-top-nav__bar-nav {
      margin-left: 1rem;
      display: flex;
      justify-content: flex-start; }
  .header .fe-top-nav__bookend {
    box-shadow: none;
    height: 48px;
    border: none; }
    .header .fe-top-nav__bookend:before, .header .fe-top-nav__bookend:after {
      display: none; }
    .header .fe-top-nav__bookend--left {
      padding-left: 16px; }
    .header .fe-top-nav__bookend--right {
      padding-right: 0px; }
      .header .fe-top-nav__bookend--right .login {
        margin-left: 0;
        margin-right: 0; }
      .header .fe-top-nav__bookend--right .avatar-menu {
        margin-right: 16px; }
    .header .fe-top-nav__bookend .logo {
      margin-top: 2px; } }

@media (max-width: 480px) {
  .header .fe-top-nav__bar-nav {
    margin-left: 0;
    justify-content: space-evenly; } }
